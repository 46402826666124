import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/react';
import Form from './form';

export default function StudioForm({ subtitle, invert, className }) {
  const [buttonText, setButtonText] = useState('Send message');

  const regions = useStaticQuery(graphql`
    query {
      regions: allSanityRegion(sort: { fields: title }) {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `);

  async function onSubmit(e, data) {
    e.preventDefault();

    setButtonText('Submitting...');

    data.append('listID', 71)

    const response = await window
      .fetch('/api/enquire', {
        method: 'POST',
        body: data,
      })
      .then(res => res.json());

    setButtonText(response);
  }

  return (<>
    <Form
      className={className}
      altTitle="Get in touch"
      invert={invert ? true : false}
      fields={[
        {
          type: 'textarea',
          name: 'message',
          placeholder: 'Message*',
          required: true,
        },
        {
          type: 'text',
          name: 'name',
          placeholder: 'Name*',
          required: true,
        },
        {
          type: 'email',
          name: 'email',
          placeholder: 'Email*',
          required: true,
        },
        {
          type: 'tel',
          name: 'phone',
          placeholder: 'Phone',
          required: false,
        },
        {
          type: 'select',
          name: 'region',
          options: regions?.regions?.nodes?.map(item => item.title),
          placeholder: 'Region*',
          required: true,
        },
      ]}
      submitErrorMessage="Please enter all required fields to submit enquiry"
      buttonText={buttonText}
      onSubmit={onSubmit}
      css={css`
        padding: var(--margin8) var(--margin);

        @media (max-width: 1024px) {
          padding: var(--margin8) var(--gutter);
        }

        @media (max-width: 550px) {
          padding: var(--margin8) var(--gutterHalf);
        }
      `}
    />
  </>);
}
