import React from 'react';
import GoogleMapReact from 'google-map-react';
import { css } from '@emotion/react';

const isClient = typeof window !== 'undefined';

export default function Logo({ address }) {
  const lat = parseFloat(address.lat);
  const lng = parseFloat(address.lng);

  return (
    <div className="google-map">
      <div
        css={css`
          display: block;
          width: 100%;
          height: 100%;
          aspect-ratio: 4 / 3;
        `}
      >
        { isClient && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCvuJWQmMe_sHrNYr8ctUpSiJOGlUNjtT0' }}
            defaultCenter={[lat, lng]}
            defaultZoom={14}
            options={{
              styles: [
                {
                  stylers: [{ saturation: -100 }],
                },
                {
                  featureType: 'all',
                  elementType: 'labels',
                  stylers: [{ visibility: 'off' }]
                },
                {
                  featureType: 'administrative.locality',
                  elementType: 'labels',
                  stylers: [{ visibility: 'on' }]
                },
                {
                  featureType: 'road',
                  elementType: 'geometry',
                  stylers: [{ visibility: 'on' }]
                },
                {
                  featureType: 'landscape',
                  elementType: 'geometry',
                  stylers: [{ color: '#ffffff' }]
                },
              ]
            }}
          >
            <div
              className="marker"
              css={css`
                position: relative;
                top: -50px;
                left: -18px;
              `}
              lat={lat}
              lng={lng}
            >
              <svg width="36" height="54" viewBox="0 0 36 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_5253_315" fill="white">
                  <path fillRule="evenodd" clipRule="evenodd" d="M32.9846 27.9765C34.8896 25.121 36 21.6902 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 21.7029 1.11814 25.1447 3.0352 28.0061L18.0001 54L32.9846 27.9765Z"/>
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M32.9846 27.9765C34.8896 25.121 36 21.6902 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 21.7029 1.11814 25.1447 3.0352 28.0061L18.0001 54L32.9846 27.9765Z" fill="white"/>
                <path d="M32.9846 27.9765L30.489 26.3117L30.4341 26.3939L30.3848 26.4795L32.9846 27.9765ZM3.0352 28.0061L5.63512 26.5093L5.58427 26.421L5.52754 26.3363L3.0352 28.0061ZM18.0001 54L15.4002 55.4968L17.9999 60.0125L20.5999 55.497L18.0001 54ZM33 18C33 21.0794 32.0753 23.9338 30.489 26.3117L35.4802 29.6414C37.7038 26.3082 39 22.3009 39 18H33ZM18 3C26.2843 3 33 9.71573 33 18H39C39 6.40202 29.598 -3 18 -3V3ZM3 18C3 9.71573 9.71573 3 18 3V-3C6.40202 -3 -3 6.40202 -3 18H3ZM5.52754 26.3363C3.9311 23.9535 3 21.09 3 18H-3C-3 22.3158 -1.69483 26.3359 0.542854 29.6759L5.52754 26.3363ZM20.6 52.5032L5.63512 26.5093L0.435275 29.5029L15.4002 55.4968L20.6 52.5032ZM30.3848 26.4795L15.4003 52.503L20.5999 55.497L35.5844 29.4735L30.3848 26.4795Z" fill="black" mask="url(#path-1-inside-1_5253_315)"/>
                <circle cx="18" cy="18" r="6.5" fill="white" stroke="black" strokeWidth="3"/>
              </svg>
            </div>
          </GoogleMapReact>
        )}
      </div>
    </div>
  );
}
